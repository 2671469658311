import cx from "classnames"
import setObjectValue from "lodash/set"
import { createContext, isValidElement, useContext, useEffect, useMemo, useRef, useState } from "react"
import { renderFormBody } from "src/helpers/form_builder"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export const AdvancedFiltersContext = createContext({})

export function AdvancedFiltersButton({ active = false, onChange, countFiltersApplied, className = null }) {
  const [activeState, setActive] = useState(active)

  useEffect(() => {
    if (activeState != active) {
      setActive(active)
    }
  }, [active])

  const { t } = useTranslation()

  const handleButtonClick = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    setActive((prev) => {
      onChange(!prev)

      return !prev
    })
  }

  return (
    <button
      className={cx(
        "ui button",
        styles.button,
        className,
        activeState && "active",
        countFiltersApplied > 0 && styles.withFilters
      )}
      type='button'
      onClick={handleButtonClick}
    >
      <i className='icon filter' />
      {t("main_ui.general.btn_advanced_filters")}
      {countFiltersApplied > 0 && <span className='ui label circular red mini floating'>{countFiltersApplied}</span>}
    </button>
  )
}

const parseFields = (fields) => {
  const clean = []

  fields.forEach((field) => {
    switch (field.name) {
      case "@usuario":
        clean.push({
          ...field,
          label: "frontend.user.__name__",
          name: "usuario",
          componentType: "select",
          choices: () => import("src/services/user.service").then((mod) => mod.getUsers()),
          formatLabel: (user) => (user?.id ? `${user.first_name} ${user.last_name}` : ""),
          add_empty: !field.multiple
        })
        break
      case "@empresa":
        clean.push({
          ...field,
          name: "empresa",
          label: "frontend.organizacion.empresa",
          componentType: "select",
          choices: () => import("src/services/paramlists.service").then((mod) => mod.getCompanies()),
          add_empty: !field.multiple
        })
        break
      case "@unidad_negocio":
        const companyEndpoint = import("src/services/paramlists.service").then((mod) => mod.getUnitBusiness)

        clean.push({
          ...field?.empresa,
          name: "empresa",
          label: "frontend.organizacion.empresa",
          componentType: "select",
          choices: () => import("src/services/paramlists.service").then((mod) => mod.getCompanies()),
          add_empty: true
        })
        clean.push({
          ...field,
          name: "unidad_negocio",
          label: "frontend.organizacion.unidad_negocio",
          componentType: "dependentselect",
          choices: (params) => companyEndpoint.then((cb) => cb(params)),
          dependsOn: ["empresa"]
        })
        break

      default:
        clean.push(field)
    }
  })

  return clean
}

const cleanObject = (value) => {
  const clean = {}
  if (value) {
    Object.entries(value).forEach(([k, v]) => {
      if (typeof v === "object") {
        const _v = cleanObject(v)
        if (Object.values(_v).length == 0) {
          clean[k] = _v
          return
        }
      }

      if (v === null || `${v}`.length === 0) {
        return
      }

      clean[k] = v
    })
  }

  return clean
}

export function AdvancedFilters({ open, fields, className = null, onClose = () => {}, button = null }) {
  const [loaded, setLoaded] = useState(false)
  const { values, setValues } = useContext(AdvancedFiltersContext)

  const clearRef = useRef(false)

  const { t } = useTranslation()

  fields = useMemo(() => parseFields(fields), [fields])

  const setValue = (field, value) => {
    setValues((prev) => {
      return cleanObject(setObjectValue({ ...prev }, field, value))
    })
  }

  const handleClickOnReset = (ev) => {
    ev.preventDefault()
    clearRef.current = true
    setValues({})
  }

  const valuesLen = Object.values(values).length

  useEffect(() => {
    if (open) {
      setLoaded(true)
    }
  }, [open])

  useEffect(() => {
    if (typeof button?.onClick === "function" && valuesLen == 0 && clearRef.current) {
      button.onClick()
      clearRef.current = false
    }
  }, [valuesLen])

  if (!loaded && !open) {
    return null
  }

  return (
    <div className={cx(styles.AdvancedFilters, className, open && styles.open)}>
      <h4 className={styles.Title}>
        <div>
          <i className='icon filter' />
          {t("main_ui.general.btn_advanced_filters")}
        </div>
        <div>
          <i className='ui icon close' onClick={onClose} title={t("main_ui.general.btn_close")} />
        </div>
      </h4>
      <div className={cx(styles.Body, "ui form")}>
        {renderFormBody({
          fields,
          values,
          setValue,
          t,
          styles,
          options: { positionLabel: "left", required: false }
        })}
      </div>
      <div className={styles.Footer}>
        <a href='#' onClick={handleClickOnReset} className='ui tertiary button blue tiny'>
          {t("main_ui.general.btn_reset_filters")}
        </a>
        {button &&
          (isValidElement(button) ? (
            button
          ) : (
            <button onClick={button.onClick} className={cx("ui button ", button.className ?? "olive")}>
              <i className='ui icon search' />
              {t(button.text ?? "main_ui.general.btn_search")}
            </button>
          ))}
      </div>
    </div>
  )
}
