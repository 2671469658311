import cx from "classnames"
import { useRouter } from "next/router"
import { createContext, useEffect, useState } from "react"
import ErrorBoundary from "src/errors/ErrorBoundary"
import useAccessControl from "src/hooks/useAccessControl"
import TabHeader from "./TabHeader"
import TabNumber from "./TabNumber"
import styles from "./style.module.scss"

export const TabContext = createContext({})
export const TabContentContext = createContext({})

export default function Tab({
  children,
  currentTab,
  tabRoutePreffix = null,
  theme = null,
  style = {},
  showTabHeaders = true,
  initialTab = null
}) {
  const router = useRouter()

  if (initialTab === null) {
    if (tabRoutePreffix === null) {
      initialTab = 0
    } else {
      const curTab = router.query[tabRoutePreffix]
      initialTab = curTab && children[curTab] ? curTab : 0
    }
  }

  const [tabSelected, setTabSelected] = useState(initialTab)
  const [renderedTabs, setRenderedTabs] = useState([initialTab])

  const { hasPermission } = useAccessControl()

  useEffect(() => {
    setTabSelected(initialTab)
  }, [initialTab])

  const headerClickHandler = (index) => {
    const onRouterPush =
      tabRoutePreffix === null
        ? Promise.resolve()
        : router.push({
            query: { ...router.query, [tabRoutePreffix]: index }
          })

    onRouterPush.then(() => {
      setTabSelected(index)
      if (currentTab) {
        currentTab(index)
      }

      setRenderedTabs((last) => {
        const updatedData = new Set([...last])
        updatedData.add(index)

        return Array.from(updatedData)
      })
    })
  }

  const renderHeaders = (tabs) => {
    return tabs?.map((child, index) => {
      if (child !== null && child !== false) {
        const disabled = child.props?.disabled ?? false

        return (
          <TabHeader
            key={index}
            id={index}
            disabled={disabled}
            isActive={index == tabSelected}
            onClickHeader={headerClickHandler}
            className={cx(styles.tabHeader)}
          >
            <h4>{child.props.headerText}</h4>
          </TabHeader>
        )
      }
    })
  }

  if (!children) {
    return null
  }

  const tabs = Array.isArray(children)
    ? children.filter(
        (x) => !(x?.props?.hidden ?? false) && (!x?.props?.permission || hasPermission(x.props.permission))
      )
    : [children]

  return (
    <TabContext.Provider value={{ setTabSelected, tabSelected }}>
      <div className={cx(styles.tab, styles[`theme-${theme}`])} style={style}>
        {showTabHeaders && tabs.length > 1 && <div className={styles.tabHeaderWrapper}>{renderHeaders(tabs)}</div>}
        {tabs.map((child, index) => {
          if (index != tabSelected && !renderedTabs.includes(index)) {
            return null
          }
          return (
            <div
              key={index}
              style={{ display: index == tabSelected ? "block" : "none" }}
              className={styles.tabBodyWrapper}
            >
              <TabContentContext.Provider value={{ tabIndex: index }}>
                <ErrorBoundary>{child}</ErrorBoundary>
              </TabContentContext.Provider>
            </div>
          )
        })}
      </div>
    </TabContext.Provider>
  )
}

Tab.Content = TabNumber
