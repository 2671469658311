import { useTranslation } from "react-i18next"
import Icon from "../../../components/Icon"

export default function FilterWarning({ text = "main_ui.general.filters_warning_text", onRemoveFilter = () => {} }) {
  const { t } = useTranslation()

  return (
    <div
      style={{
        backgroundColor: "#ffae39",
        color: "#fff",
        padding: 15,
        display: "flex",
        gap: 5,
        alignItems: "center"
      }}
    >
      <Icon name='tree/finding' color='#fff' />
      <span>
        {t("main_ui.general.lb_note")}: {t(text)}
      </span>
      <a
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault()
          onRemoveFilter()
        }}
      >
        {t("main_ui.general.filters_clear_btn")}
      </a>
    </div>
  )
}
