import cx from "classnames"
import Tooltip from "src/components/Tooltip"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export default function TabHeader({ children, isActive, disabled = false, onClickHeader, id, className = null }) {
  const { t } = useTranslation()

  return (
    <div
      className={cx(styles.tabHeader, isActive && styles.tabHeaderActive, disabled && styles.disabled, className)}
      onClick={disabled ? null : onClickHeader.bind(this, id)}
    >
      <Tooltip disabled={!disabled} content={t("main_ui.general.error_unauthorized_action")}>
        {children}
      </Tooltip>
    </div>
  )
}
