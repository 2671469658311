export default function TabNumber({ headerText = null, content, disabled = false, children }) {
  if (disabled) {
    return null
  }
  if (content) {
    return typeof content === "function" ? content() : content
  }

  return <div>{children}</div>
}
