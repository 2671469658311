import { getYear } from "date-fns"

export const getListYears = (orderType = "ASCENDANT") => {
  const yearList = []

  const year = getYear(new Date())

  for (let i = 2018; i <= year + 1; i++) {
    yearList.push({
      id: i,
      label: i,
      selected: false
    })
  }

  const compare = (a, b) => {
    switch (orderType) {
      case "ASCENDANT":
        return a.id - b.id

      case "DESCENDANT":
        return b.id - a.id
    }

    return null
  }

  return yearList.sort(compare)
}
