import { useCallback, useReducer, useState } from "react"
import {
  AdvancedFilters,
  AdvancedFiltersButton,
  AdvancedFiltersContext
} from "src/components/Filter/AdvancedFiltersButton"
import FilterWarning from "src/macrocomponents/Tree/FilterWarning"

const filtersReducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUE":
      return Object.assign({}, action.value)
    case "ADD_VALUE":
      return Object.assign({}, state, action.value)
    default:
      return state
  }
}

export default function useAdvancedFilters({ fields, defaultValues = {} }) {
  const [values, dispatch] = useReducer(filtersReducer, defaultValues)
  const [open, setOpen] = useState(Object.values(defaultValues).length > 0)

  const setValues = (newValue) => {
    if (typeof newValue == "function") {
      dispatch({ type: "SET_VALUE", value: newValue(values) })
    } else {
      dispatch({ type: "SET_VALUE", value: newValue })
    }
  }

  const addValues = (newValue) => {
    dispatch({ type: "ADD_VALUE", value: newValue })
  }

  const handleChangeOpen = (v) => {
    setOpen(v)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const clear = () => {
    setOpen(false)
    setValues({})
  }

  const isEmpty = () => {
    return Object.values(values).length == 0
  }

  const countFiltersApplied = Object.values(values).length

  const Button = useCallback(
    (props) => (
      <AdvancedFiltersButton
        active={open}
        countFiltersApplied={countFiltersApplied}
        onChange={handleChangeOpen}
        {...props}
      />
    ),
    [open, countFiltersApplied]
  )

  const Panel = useCallback(
    (props) => (
      <AdvancedFiltersContext.Provider value={{ values, setValues }}>
        <AdvancedFilters
          open={open}
          fields={fields}
          onClose={handleClose}
          className={props?.className}
          button={props?.button}
        />
        {!open && countFiltersApplied > 0 && <FilterWarning onRemoveFilter={() => clear()} />}
      </AdvancedFiltersContext.Provider>
    ),
    [open, values, fields]
  )

  return {
    Button,
    Panel,
    open,
    setOpen,
    values,
    setValues,
    addValues,
    isEmpty,
    clear
  }
}
