import React, { useState } from "react"
import { getListYears } from "src/helpers/years-list"
import Select from "../.."
import useTranslation from "src/hooks/useTranslation"

const SelectYear = ({ onSelect, defaultYear, order = "DESCENDANT", allowClear = false }) => {
  const { t } = useTranslation()

  const yearOptions = getListYears(order)
  if (allowClear) {
    yearOptions.unshift({ id: null, label: t("main_ui.general.lb_choice_all"), selected: false })
  }
  const [selectedYear, setSelectedYear] = useState(defaultYear)

  const onSelectHandler = ({ id }) => {
    setSelectedYear(id)
    onSelect(id)
  }

  return (
    <div>
      <Select
        selectionChange={onSelectHandler}
        optionsMaxWidth={100}
        initialOptions={yearOptions.map((x) => {
          return { ...x, selected: x.id == selectedYear }
        })}
      />
    </div>
  )
}

export default SelectYear
