import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormField from "src/components/FormField"
import Select from "src/components/FormField/Select"
import { getCompanies, getUnitBusiness } from "src/services/paramlists.service"

export default function EmpresaFilters({
  empresaValue = null,
  onChangeEmpresa = null,
  unValue = null,
  onChangeUN = null,
  positionLabel = "top",
  autoSelectFirst = true,
  emptyLabel = true,
  unEmptyLabel = null,
  unAll = false,
  disabled = false,
  onLoad = null,
  onLoadUN = null,
  includeUN = true
}) {
  const [selectedCompany, setSelectedCompany] = useState(empresaValue)

  const [selectedUN, setSelectedUN] = useState(unValue)
  const [selectableCompanies, setSelectableCompanies] = useState(null)
  const [loadedUn, setLoadedUn] = useState(false)

  const { t } = useTranslation()

  if (emptyLabel === true) {
    emptyLabel = t("main_ui.general.filter_choice_all")
  } else if (emptyLabel === false) {
    emptyLabel = ""
  }

  const emitOnLoadUN = (v) => {
    if (typeof onLoadUN === "function" && includeUN) {
      onLoadUN(v)
    }
  }

  useEffect(() => {
    if (selectableCompanies) {
      return
    }

    getCompanies().then((res) => {
      let sid = selectedCompany
      if (!res.data.find((x) => x.id === selectedCompany)) {
        sid = localStorage.getItem("ABI.auditoria.selectedCompany")
        if (!res.data.find((x) => x.id === sid)) {
          if (autoSelectFirst) {
            sid = res.data[0]?.id
          } else {
            sid = null
          }
        }

        setSelectedCompany(sid)
        onChangeEmpresa(sid)
        handleChangeUN({ id: null })
      }

      const list = res.data.map((c) => {
        return {
          id: c.id,
          label: c.nombre,
          selected: sid == c.id
        }
      })

      setSelectableCompanies(emptyLabel === null ? list : [{ id: "", label: emptyLabel, selected: !sid }].concat(list))
      if (onLoad) {
        onLoad(list)
      }
    })
  }, [])

  useEffect(() => {
    setSelectedCompany(empresaValue)

    if (!selectableCompanies) {
      return
    }

    setSelectableCompanies((prevValues) => {
      return prevValues.map((c) => {
        return { ...c, selected: empresaValue ? empresaValue == c.id : c.id === "" }
      })
    })
  }, [empresaValue])

  useEffect(() => {
    if (selectedUN != unValue) {
      setSelectedUN(unValue)
    }
  }, [unValue])

  useEffect(() => {
    if (!selectedCompany) {
      emitOnLoadUN(null)
      return
    }

    if (!selectableCompanies) {
      emitOnLoadUN(null)
      return
    }

    if (!includeUN) {
      return
    }

    const company = selectableCompanies.find((item) => item.id == selectedCompany)

    if (!company) {
      emitOnLoadUN(null)
      return
    }

    if (typeof company["businessUnits"] == "undefined") {
      setLoadedUn(false)
      getUnitBusiness({ empresa: selectedCompany }).then((res) => {
        setSelectableCompanies((prevValues) => {
          return prevValues.map((c) => {
            if (c.id === selectedCompany) {
              return { ...c, ...{ businessUnits: res.data } }
            } else {
              return c
            }
          })
        })
        setLoadedUn(true)
        emitOnLoadUN(res.data)
      })
    } else {
      emitOnLoadUN(company["businessUnits"])
    }
  }, [selectableCompanies, includeUN, selectedCompany])

  const isDisabled = (field) => {
    if (typeof disabled === "boolean") {
      return disabled
    }

    if (typeof disabled === "object") {
      return disabled[field]
    }

    return disabled
  }

  const handleChangeEmpresa = ({ id }) => {
    setSelectedCompany(id)
    setSelectedUN(null)

    if (onChangeEmpresa) {
      onChangeEmpresa(id)
    }
  }

  const handleChangeUN = ({ id }) => {
    setSelectedUN(id ? id : null)

    if (onChangeUN) {
      onChangeUN(id ? id : null)
    }
  }

  const formatUNList = useCallback(() => {
    if (!selectedCompany || !selectableCompanies) {
      return []
    }

    const company = selectableCompanies.find((item) => item.id == selectedCompany)
    if (typeof company.businessUnits == "undefined") {
      return null
    }

    if (loadedUn === false) {
      return null
    }

    if (selectedUN && !["-1"].includes(selectedUN)) {
      const found = company.businessUnits.find((x) => x.id === selectedUN)
      if (!found) {
        handleChangeUN({ id: null })
      }
    }

    const v = [
      { id: "", label: unEmptyLabel !== null ? unEmptyLabel : emptyLabel === null && unAll ? "- TODOS -" : emptyLabel, selected: !selectedUN }
    ].concat(
      company.businessUnits.map((item) => {
        return { id: item.id, label: item.nombre, selected: item.id == selectedUN }
      })
    )
    if (unAll && (emptyLabel !== null || unEmptyLabel !== null)) {
      v.push({ id: "-1", label: "- TODOS -", selected: "-1" == selectedUN })
    }

    return v
  }, [selectedCompany, selectedUN, loadedUn])

  return (
    <>
      <FormField label='frontend.organizacion.empresa' positionLabel={positionLabel} disabled={isDisabled("empresa")}>
        <Select
          selectionChange={handleChangeEmpresa}
          initialOptions={selectableCompanies}
          disabled={isDisabled("empresa")}
        />
      </FormField>

      {includeUN && (
        <FormField
          label='frontend.organizacion.unidad_negocio'
          positionLabel={positionLabel}
          disabled={isDisabled("unidad_negocio")}
        >
          <Select
            disabled={
              isDisabled("un") ? true : { "main_ui.general.filter_unidadnegocio_disabledtext": !selectedCompany }
            }
            selectionChange={handleChangeUN}
            initialOptions={selectedCompany ? formatUNList() : [{ id: "", label: "", selected: true }]}
          />
        </FormField>
      )}
    </>
  )
}
