import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getEvaluations = authValidationMiddleware(
  async ({
    entriesPerPage = undefined,
    page = 1,
    company = undefined,
    start = "",
    end = "",
    copy_type = null,
    ano_evaluacion = null,
    filters = {},
    ...additionalParams
  }) => {
    const params = { ...filters, ...additionalParams }

    params["max_per_page"] = entriesPerPage
    params["page"] = page
    if (company) {
      params["empresa"] = company
    }
    if (copy_type) {
      params["copy_type"] = copy_type
    }

    if (typeof start === "string" && start !== "") {
      params["fecha_inicio"] = start
    }

    if (typeof end === "string" && end !== "") {
      params["fecha_fin"] = end
    }

    if (ano_evaluacion) {
      params["ano_evaluacion"] = ano_evaluacion
    }

    const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/`, { params })

    return response.data
  }
)

export const getEvaluationsByYear = authValidationMiddleware(async ({ year }) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/?ano_evaluacion=${year}`)

  return response.data
})

export const getEvaluation = authValidationMiddleware(async (evaluationId, params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluationId}/`, { params })

  return response.data
})

export const getListRisks = authValidationMiddleware(async (evaluationId, params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluationId}/riesgos/`, { params })

  return response.data
})

export const getListGenericAction = authValidationMiddleware(async (name, params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${name}/`, { params })

  return response.data
})

export const createEvaluation = authValidationMiddleware(async (obj) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/evaluacion/`, {
    ...obj
  })

  return response.data
})

export const deleteEvaluation = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/proyecto/riesgo/evaluacion/${id}/`)

  return response.data
})

export const updateEvaluation = authValidationMiddleware(async ({ evaluationId, evaluationBody }) => {
  const response = await axiosApiInstance.put(`/proyecto/riesgo/evaluacion/${evaluationId}/`, {
    ...evaluationBody
  })

  return response.data
})

export const getListObjectGenericAction = authValidationMiddleware(async (evaluationId, name, params = {}) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluationId}/${name}/`, { params })

  return response.data
})

export const createEvaluationSubdivision = authValidationMiddleware(async (evaluacionId, obj) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/evaluacion/${evaluacionId}/subdivision/`, obj)

  return response.data
})

export const listEvaluationSubdivision = authValidationMiddleware(async (evaluacionId) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluacionId}/subdivision/`)

  return response.data
})

export const retrieveEvaluationSubdivision = authValidationMiddleware(async (evaluacionId, id) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluacionId}/subdivision/${id}/`)

  return response.data
})

export const updateEvaluationSubdivision = authValidationMiddleware(async (evaluacionId, id, obj) => {
  const response = await axiosApiInstance.put(`/proyecto/riesgo/evaluacion/${evaluacionId}/subdivision/${id}/`, obj)

  return response.data
})

export const deleteEvaluationSubdivision = authValidationMiddleware(async (evaluacionId, id) => {
  const response = await axiosApiInstance.delete(`/proyecto/riesgo/evaluacion/${evaluacionId}/subdivision/${id}/`)

  return response.data
})
