import { getAudit } from "./audit.service"
import authValidationMiddleware from "./authValidationMiddleware"
import { getEvaluation } from "./evaluation.service"
import axiosApiInstance from "./httpInterceptor"

export const listAllProjects = authValidationMiddleware(
  async ({ type, year, empresa, unidad_negocio, ...additionalParams }) => {
    let url
    const params = { max_per_page: 1000, ...additionalParams }

    if (type == "auditoria") {
      url = "/proyecto/riesgo/auditoria/list_all/"
      params.ano_auditoria = year
      params.mode = "mini"
    } else if (type == "evaluacion") {
      url = "/proyecto/riesgo/evaluacion/"
      if (year) {
        params.ano_evaluacion = year
      }
    }

    if (unidad_negocio) {
      params.unidad_negocio = unidad_negocio
    }

    if (empresa) {
      params.empresa = empresa
    }

    const response = await axiosApiInstance.get(url, { params })
    if (type == "auditoria") {
      response.data.data = { records: response.data.data }
    }
    return response.data
  }
)

export const listUserProjects = authValidationMiddleware(async ({ type, user_id, ...additionalParams }) => {
  let url
  const params = { user_id, ...additionalParams }

  if (type == "auditoria") {
    url = "/proyecto/riesgo/auditoria/list_by_user/"
  } else if (type == "evaluacion") {
    url = "/proyecto/riesgo/evaluacion/list_by_user/"
  }

  const response = await axiosApiInstance.get(url, { params })
  if (type == "auditoria") {
    response.data.data = { records: response.data.data }
  }
  return response.data
})

export const getProject = authValidationMiddleware(async (projectType, id, params = {}) => {
  switch (projectType) {
    case "auditoria":
      return getAudit(id, params)
    case "evaluacion":
      return getEvaluation(id, params)
  }
})

export const listAllRiskAudits = authValidationMiddleware(async ({ groupBy = null, year = null }) => {
  const url = "/proyecto/riesgo/auditoria/list_all/"
  const requestConfig =
    year !== null
      ? {
          params: {
            ano_auditoria: year
          }
        }
      : undefined

  const response = await axiosApiInstance.get(url, requestConfig)

  return response.data
})

export const updateRiskAudit = authValidationMiddleware(async ({ payload = null, auditId = "" }) => {
  const url = `/proyecto/riesgo/auditoria/${auditId}/`

  const requestConfig = payload !== null ? payload : undefined

  const response = await axiosApiInstance.put(url, requestConfig)

  return response.data
})

export const updateRiskAuditTeamMember = authValidationMiddleware(
  async ({ payload = null, auditId = "", memberId = "" }) => {
    const url = `/proyecto/riesgo/auditoria/${auditId}/equipo/${memberId}/`

    const requestConfig = payload !== null ? payload : undefined

    const response = await axiosApiInstance.put(url, requestConfig)

    return response.data
  }
)

export const listAllRiskAuditsMini = authValidationMiddleware(async (groupBy = null) => {
  const url = "/proyecto/riesgo/auditoria/list_all/?mode=mini"

  const requestConfig =
    groupBy !== null
      ? {
          params: {
            groupby: groupBy
          }
        }
      : undefined

  const response = await axiosApiInstance.get(url, requestConfig)

  return response.data
})

export const updatePriorizacionValue = authValidationMiddleware(async (idEvaluation, criterio, value, id_division) => {
  const url = "/proyecto/riesgo/priorizacion/"

  const data = {
    evaluacion: idEvaluation,
    criterio,
    valor: value
  }

  if (id_division) {
    data.subdivision = id_division
  }

  const response = await axiosApiInstance.post(url, data)

  return response.data
})
